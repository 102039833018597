import { FiltreData } from '@innedit/innedit';
import { useSelector } from 'dataformjs';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '../../core/sysext/Boutique/containers/Filtre/components/List/Item';
import params from '../../core/sysext/Boutique/containers/Filtre/params.json';
import CMSView from '../../core/sysext/CMS/components/View';
import Backoffice from '../../core/sysext/CMS/containers/Backoffice';
import Form from '../../core/sysext/CMS/containers/Boutique/components/new/Form';
import List from '../../core/sysext/CMS/containers/Boutique/components/new/List';

const FiltresPage: FC<PageProps> = props => {
  const {
    location,
    params: { '*': star },
  } = props;
  const { t } = useTranslation();

  const boutique = useSelector((state: any) =>
    state.cms.boutiques
      ? state.cms.boutiques[state.cms.indexBoutique || 0]
      : undefined,
  );

  const model = new FiltreData({
    boutique,
    params,
    orderDirection: 'asc',
    orderField: 'libelle',
  });

  return (
    <Backoffice location={location}>
      <CMSView>
        {!star ? (
          <List
            {...props}
            formName="filtre"
            item={Item}
            itemPathnamePrefix="/boutique/filtres/"
            model={model}
            nbParPage={50}
            title={t('filtres')}
          />
        ) : (
          <Form
            {...props}
            docId={star}
            itemPathnamePrefix="/boutique/filtres/"
            model={model}
            name="filtre"
          />
        )}
      </CMSView>
    </Backoffice>
  );
};

export default FiltresPage;
